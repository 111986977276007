<template>
  <b-modal 
  id="qr-show-modal" 
  title="Scan QR Code" 
  centered hide-footer 
  size="lg" 
  :no-close-on-esc="true" 
  :no-close-on-backdrop="true" 
  >
  <div class="d-flex justify-content-center align-items-center">
    <b-img :src="url" rounded fluid class="qr-code-img"></b-img>
    </div>
  </b-modal>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import util from "@/util.js";

export default {
  props: {
    url: {
      type: String,
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
    draggable,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
      isBusy: false,
    };
  },
  mixins: [util],

  methods: {
   
    async onModalClosed() {
      await this.fetchPaginatedData();
      this.visible = false;
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getLoggedInUser",
    }),
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>

.qr-code-img {
 width: 50%; 
height: 50%; 
object-fit: center/cover no-repeat;
}

</style>