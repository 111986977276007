<template>
  <div>
    <b-modal
      id="room-show-modal"
      title="Room details"
      centered
      hide-footer
      size="lg"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      @hide="onModalClosed"
    >
      <div>
        <b-button
          type="submit"
          variant="success"
          class="mb-1"
          @click="downloadPDF"
        >
          Download
        </b-button>
      </div>
      <div v-if="!isBusy">
        <!-- <div><h4>{{ messName }}</h4></div> -->
        <b-table :items="fetchedRooms" :fields="fields" small striped outlined>
          <template #cell(name)="row">
            {{ row.item.name }}
          </template>
          <template #cell(messName)="row">
            {{ row.item.messName }}
          </template>
          <template #cell(qrCode)="row">
            <img :src="row.item.qrCode" fluid />
          </template>
        </b-table>
      </div>
      <div v-else>Not Responding</div>
    </b-modal>
    <qr-show-modal
      @modalClosed="onModalClosed"
      :showModal="visible"
      :url="url"
      :key="`view-${QrShowModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import QrShowModal from "@/components/booking/admin/QrShowModal.vue";
import html2pdf from "html2pdf.js";

export default {
  components: {
    QrShowModal,
  },
  props: {
    messId: Number,
  },
  data() {
    return {
      isBusy: false,
      fetchedRooms: [],
      url: "",
      QrShowModalCount: 0,
      visible: false,
      fields: [
        { key: "name", label: "Room Name" },
        { key: "qrCode", label: "QR Code" },
        // {key:"messName", label: this.mess}
      ],
      messName: "",
    };
  },
  methods: {
    ...mapActions({
      getRoomDetails: "appData/getRoomDetails",
    }),

    async fetchRooms() {
      try {
        let res = await this.getRoomDetails(this.messId);
        if (
          res.data[0] &&
          res.data[0].mess_data &&
          res.data[0].mess_data.name
        ) {
          this.messName = res.data[0].mess_data.name;
        } else {
          console.error(
            "Mess data or mess name not found in response:",
            res.data
          );
        }

        this.fetchedRooms = res.data.map((room) => ({
          id: room.id,
          name: room.name,
          qrCode: room.feedback_qr_code,
        }));
      } catch (error) {
        console.error("Error fetching rooms:", error);
      }
    },

    onModalClosed() {
      this.fetchedRooms = [];
    },

    async downloadPDF() {
      const tableData = [];
      const container = document.createElement("div");
      const table = document.createElement("table");
      const tbody = document.createElement("tbody");
      const headers = [...this.fields.map((field) => field.label)];
      const messNameIndex = 1;
      headers.splice(messNameIndex, 0, this.messName);

      tableData.push(headers);
      const fetchImageAndConvert = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      };

      if (!this.fields || this.fields.length === 0) {
        console.error("Fields array is empty or undefined");
        return;
      }

      for (const room of this.fetchedRooms) {
        const rowData = [];

        for (const field of this.fields) {
          if (field.key === "qrCode") {
            const imageData = await fetchImageAndConvert(room.qrCode);
            rowData.push({ image: imageData });
          } else {
            rowData.push(room[field.key]);
          }
        }

        tableData.push(rowData);
      }

      let maxRowHeight = 0;
      tableData.slice(1).forEach((row) => {
        const rowHeight = row.reduce((acc, cell) => {
          if (cell.image) {
            return Math.max(acc, 40);
          } else {
            const cellHeight = cell.toString().length * 4;
            return Math.max(acc, cellHeight);
          }
        }, 0);
        maxRowHeight = Math.max(maxRowHeight, rowHeight);
      });
      const filteredHeaders = headers.filter(
        (header) => header !== "Room Name" && header !== "QR Code"
      );
      tableData.forEach((row, rowIndex) => {
        const tr = document.createElement("tr");
        row.forEach((cell, cellIndex) => {
          const td = document.createElement("td");
          if (cell.image) {
            const img = document.createElement("img");
            img.src = cell.image;
            img.style.width = "400px";
            img.style.height = "400px";
            img.style.marginTop = "105px";
            img.style.paddingRight= "30px";
            tr.style.pageBreakInside = "avoid";
            tr.style.paddingTop = "20px";

            td.appendChild(img);
          } else {
            td.textContent = cell;
            if (cellIndex === 0 && rowIndex > 0) {
              td.style.paddingLeft = "160px";
              td.style.marginBottom = "20px";
              td.style.fontWeight = "bold";
              td.style.fontSize = "20px";
              td.style.color = "Black";
            }
          }

          tr.appendChild(td);
        });
        tbody.appendChild(tr);
        if (rowIndex === 0) {
          tr.childNodes.forEach((headerCell, index) => {
            if (index === 0) {
              headerCell.style.paddingLeft = "360px";
            } else {
              headerCell.style.paddingLeft = "130px";
            }
            headerCell.style.backgroundColor = "#46755a";
            headerCell.style.color = "#fff";
            headerCell.style.fontWeight = "bold";
            headerCell.style.height = `${maxRowHeight}px`;
            headerCell.style.textAlign = "left";
            headerCell.textContent = filteredHeaders[index];
          });
        }
      });

      table.appendChild(tbody);
      container.appendChild(table);

      // table.style.borderCollapse = "collapse";
      table.style.width = "100%";
      // table.style.border = "2px solid #333";
      // table.style.borderRadius = "8px";  

      Array.from(table.children).forEach((row) => {
        Array.from(row.children).forEach((cell) => {
          // cell.style.border = "1px solid #333";
          cell.style.padding = "10px";
          cell.style.marginTop = "30px";
        });
      });

      Array.from(table.querySelectorAll("tr:nth-child(even)")).forEach(
        (row) => {
          row.style.backgroundColor = "#f2f2f2";
        }
      );
            html2pdf().from(container).save("room_details.pdf");
    },

    showQr(item) {
      this.QrShowModalCount += 1;
      this.url = item;
      this.visible = true;
      this.$nextTick(() => {
        this.$bvModal.show("qr-show-modal");
      });
    },
  },

  mounted() {
    if (this.messId) {
      this.fetchRooms();
    }
  },
};
</script>
