<template>
    <b-modal id="mess-edit-modal" title="Mess Edit Modal" hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" body-class="position-static">
      <template #modal-title>
        <h2 v-if="allowEdit" class="m-0">Edit Mess</h2>
        <h2 v-else class="m-0">View Mess</h2>
      </template>
      <validation-observer ref="messEditFormValidation">
        <b-form @submit.prevent>
          <b-row>
            <b-col>
              <b-form-group label="Name" label-for="name">
                <template #label>
                  Mess Name <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="Name"
                    :rules="{ required, regex: /^[a-zA-Z0-9 ,&-.()'']*$/i }">
                      <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false : null"
                      placeholder="Name" :disabled="!allowEdit"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Description" label-for="description">
                <template #label>
                  Mess Description <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="Description"
                      :rules="{ required, regex: /^[a-zA-Z0-9 ,-.()'']*$/i }">
                      <b-form-input id="description" v-model="description" :state="errors.length > 0 ? false : null"
                      placeholder="Description" :disabled="!allowEdit"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Phone" label-for="phone">
                <template #label>
                  Mess Contact Number <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="Phone"
                      :rules="{ required, regex: /^[0-9-+]*$/, length: 11 }">
                      <b-form-input id="phone" v-model="phone" :state="errors.length > 0 ? false : null"
                      placeholder="Phone" :disabled="!allowEdit"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Address" label-for="address">
                <template #label>
                  Mess Address <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="Address"
                      :rules="{ required, regex: /^[A-Z-,.#&()\s]*$/i }">
                      <b-form-input id="address" v-model="address" :state="errors.length > 0 ? false : null"
                      placeholder="Address" :disabled="!allowEdit"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
              <b-col>
                  <!-- <h6>Kindly attach pictures</h6> -->
                  <!-- <b-form-file v-model="pictures" class="mt-0" :state="Boolean(pictures)"
                  accept="image/*" @change="uploadPicture($event)"
                  placeholder="Upload Pictures" drop-placeholder="Drop files here" ref="pictures" multiple /> -->
                  <!-- <b-form-file v-model="pictures" :state="Boolean(pictures)" accept="image/*" @change="uploadPicture($event)"  multiple>
                    <template slot="file-name" slot-scope="{ pictureNames }">
                      <b-badge variant="primary" v-for="picture in pictures">{{ picture.name }}</b-badge>
                      <b-badge variant="primary">{{ pictures[0].name }}</b-badge>
                      <b-badge  variant="primary" class="ml-1">
                        + {{ pictures.length - 1 }} More files
                      </b-badge>
                    </template>
                  </b-form-file> -->
  
  
                  <b-form-group label-for="messPictures">
                    <template #label> Mess Pictures </template>
                    <b-form-file
                      id="messPictures"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      multiple
                      :accept="acceptedFiles.join(', ')"
                      @change="uploadMessPicture"
                      :file-name-formatter="formatMessPictureNames"
                      ref="messPictures"
                      :disabled="!allowEdit"
                    />
                    <small class="text-danger" v-if="documentError === 1">
                      Maximum of 5 files allowed {{ acceptedFiles.join(", ") }}
                    </small>
                    <small class="text-danger" v-if="documentError === 3">
                      Supported File Types are {{ acceptedFiles.join(", ") }}
                    </small>
                    <div class="d-flex flex-wrap">
                      <b-badge
                        pill
                        variant="primary"
                        v-for="(file, index) in pictures"
                        :key="index"
                        class="mr-50 mt-50"
                      >
                        {{ file.name }}
                        <feather-icon
                          size="24"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeMessPicture(index)"
                          v-if="allowEdit"
                        />
                      </b-badge>
                    </div>
                  </b-form-group>
  
  
              </b-col>
          </b-row>
          <template v-if="pictureURLs">
              <b-row class="mb-1">
                  <b-col v-for="(url, index) in pictureURLs" :key="index">
                      <b-img :src="url" rounded fluid> </b-img>
                  </b-col>
              </b-row>
          </template>
          <!-- <b-row>
            <b-col>
              <validation-provider #default="{ errors }" name="Hotel" rules="required">
              <b-form-group label-for="hotel" :state="errors.length > 0 ? false : null">
                  <template #label>
                  Hotel <span class="text-danger">*</span>
                  </template>
                  <b-row>
                      <b-col>
                          <v-select id="hotel" inputId="id" label="name" v-model="selectedHotel"
                          :options="hotels" placeholder="Hotel" class="v-style-chooser" :disabled="!allowEdit"/>
                      </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col>
              <validation-provider #default="{ errors }" name="ManagingHq" rules="required">
              <b-form-group label-for="managingHq" :state="errors.length > 0 ? false : null">
                  <template #label>
                  Managing HQ <span class="text-danger">*</span>
                  </template>
                  <b-row>
                      <b-col>
                          <v-select id="managingHq" inputId="id" label="name" v-model="selectedManagingHq"
                          :options="managingHqs" placeholder="Managing HQ" class="v-style-chooser" :disabled="!allowEdit"/>
                      </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider #default="{ errors }" name="City" rules="required">
              <b-form-group label-for="city" :state="errors.length > 0 ? false : null">
                  <template #label>
                  City <span class="text-danger">*</span>
                  </template>
                  <b-row>
                      <b-col>
                          <v-select id="city" inputId="id" label="name" v-model="selectedCity"
                          :options="cities" placeholder="City" class="v-style-chooser" :disabled="!allowEdit"/>
                      </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
          <b-col>
            <b-form-group label="Account Title" label-for="accountTitle">
              <template #label>
                Account Title <span class="text-danger">*</span>
              </template>
                <validation-provider #default="{ errors }" name="accountTitle"
                   :rules="{ required, regex: /^[A-Z\s\S]*$/i }">
                    <b-form-input id="accountTitle" v-model="accountTitle" :state="errors.length > 0 ? false : null"
                    placeholder="Account Title" :disabled="!allowEdit" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <validation-provider #default="{ errors }" name="bank" rules="required">
            <b-form-group label-for="bank" :state="errors.length > 0 ? false : null">
                <template #label>
                Bank <span class="text-danger">*</span>
                </template>
                <b-row>
                    <b-col>
                        <v-select id="bank" label="name" v-model="selectedBank"
                        :options="banks" placeholder="Bank" :disabled="!allowEdit" class="v-style-chooser" />
                    </b-col>
                </b-row>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- <b-form-radio-group class="mt-1 mb-1" id="checkType" v-model="checkType" :disabled="!allowEdit">
            <b-form-radio v-model="checkType" :value="true">
              Account Number
            </b-form-radio>
            <b-form-radio v-model="checkType" :value="false">
              IBAN
            </b-form-radio>
        </b-form-radio-group>
        <div v-if="checkType">
          <b-row>
          <b-col>
            <b-form-group label="Account Number" label-for="accountNumber">
              <template #label>
                Account Number <span class="text-danger">*</span>
              </template>
                <validation-provider #default="{ errors }" name="accountNumber"
                    :rules="{ required: checkType, regex: /^[0-9]*$/ }">
                    <b-form-input id="accountNumber" v-model="accountNumber" :state="errors.length > 0 ? false : null"
                    placeholder="Account Number" :disabled="!allowEdit" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </div> -->
      <div>
        <b-row>
          <b-col>
            <b-form-group label="Iban" label-for="iban">
              <template #label>
               Iban <span class="text-danger">*</span>
              </template>
                <validation-provider #default="{ errors }" name="Iban"
                    :rules="{ required, regex: /^PK[A-Z0-9]{22}$/}">
                    <b-form-input id="iban" v-model="iban" :state="errors.length > 0 ? false : null"
                    placeholder="Iban" :disabled="!allowEdit" />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

          <!-- <b-row>
            <b-col md="6">
              <b-form-group label="Location" label-for="location">
                <template #label>
                  Location <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="Location"
                      :rules="{ required, regex: /^[A-Z\s]*$/i }">
                      <b-form-input id="location" v-model="location" :state="errors.length > 0 ? false : null"
                      placeholder="Location" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col md="12" sm="12" lg="12" xl="12">
              <validation-provider #default="{ errors }" name="RoomTypes" rules="required">
              <b-form-group label-for="roomTypes" :state="errors.length > 0 ? false : null">
                  <template #label>
                  Room Types <span class="text-danger">*</span>
                  </template>
                  <b-row>
                      <b-col>
                          <v-select id="roomTypes" inputId="id" label="name" v-model="selectedRoomTypes"
                          :options="roomTypes" placeholder="Room Types" class="v-style-chooser" multiple disabled/>
                      </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
              </validation-provider>
            </b-col>
            <!-- <b-col md="3" sm="3" lg="3" xl="3">
              <b-button size="md" class="mt-2" variant="primary" @click="selectAllRoomTypes" disabled>
                Select All
              </b-button>
            </b-col> -->
          </b-row>
          <template>
            <!-- <b-row v-for="(roomType, index) in selectedRoomTypes" :key="'price_'+index">
              <b-col md="12" sm="12" lg="12" xl="12">
                <b-form-group :label-for="`${roomType.name}RoomTypePrice`">
                  <template #label> {{ roomType.name }} room type price </template>
                  <validation-provider #default="{ errors }" :name="`${roomType.name}RoomTypePrice`"
                      :rules="{ required, regex: /^[0-9-+]*$/i }">
                      <b-form-input :id="`${roomType.name}RoomTypePrice`" :v-model="`${roomTypePrices[roomType.name]}`" :state="errors.length > 0 ? false : null"
                      :placeholder="`${roomType.name} Room Type Price`" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row> -->
  
            <!-- <b-row v-for="(value, key) in roomTypePrices" :key="`price_${key}`">
              <b-col md="12" sm="12" lg="12" xl="12">
                !-- @change="changeRoomTypePrice(key, $event)" --
                <b-form-group :label-for="`${key}RoomTypePrice`">
                  <template #label> {{ key }} room type price </template>
                  <validation-provider #default="{ errors }" :name="`${key}RoomTypePrice`"
                      :rules="{ required, regex: /^[0-9]*$/i }">
                      <b-form-input :id="`${key}RoomTypePrice`"  v-model="roomTypePrices[key]" :state="errors.length > 0 ? false : null"
                      :placeholder="`${key} Room Type Price`" :ref="`${key}RoomTypePrice`" :disabled="!allowEdit"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row> -->

            <b-row v-for="(value, key) in roomTypePrices" :key="`price_${key}`">
              <b-col md="12" sm="12" lg="12" xl="12">
                <strong>{{ key }}</strong>
                <b-row>
                  <b-col v-for="(value_userType, key_userType) in userTypes" :key="`userType_${key_userType}`" md="4" sm="4" lg="4" xl="4">
                  <!-- <b-col v-for="(value_userType, key_userType) in value" :key="`userType_${key_userType}`" md="4" sm="4" lg="4" xl="4"> -->
                    <b-form-group :label-for="`${key}-${key_userType}-RoomTypePrice`">
                      <template #label> Price for {{ userTypeNames(value_userType) }} persons  </template>
                      <validation-provider #default="{ errors }" :name="`${key}-${key_userType}-RoomTypePrice`"
                          :rules="{ required, regex: /^[0-9]*$/i }">
                          <template v-if="key in roomTypePrices">
                          <!-- <b-form-input :id="`${key}-${key_userType}-RoomTypePrice`"  v-model="roomTypePrices[key][userTypeNames(value_userType)]" :state="errors.length > 0 ? false : null"
                          :placeholder="`${key} Room Type Price`" :ref="`${key}-${key_userType}-RoomTypePrice`"/> -->
                          <b-form-input :id="`${key}-${key_userType}-RoomTypePrice`"  v-model="roomTypePrices[key][value_userType]" :state="errors.length > 0 ? false : null"
                          :placeholder="`${key} Room Type Price`" :ref="`${key}-${key_userType}-RoomTypePrice`" :disabled="!allowEdit"/>
                          </template>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
  
          <template>
            <b-row v-for="(roomType, index) in selectedRoomTypes" :key="'picture_'+index">
  
              <b-col md="12" sm="12" lg="12" xl="12">
          
                <b-form-group :label-for="`${roomType.name}RoomTypePictures`">
                  <template #label> {{ roomType.name }} room type pictures </template>
                  <b-form-file
                    :id="`${roomType.name}RoomTypePictures`"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    multiple
                    :accept="acceptedFiles.join(', ')"
                    @change="uploadRoomTypePicture(roomType.name, $event)"
                    :file-name-formatter="() => { return `${roomTypePictures[roomType.name].length} files selected` }"
                    :ref="`${roomType.name}RoomTypePictures`"
                    :disabled="!allowEdit"
                  />
                    <small class="text-danger" v-if="documentError === 1">
                      Maximum of 5 files allowed {{ acceptedFiles.join(", ") }}
                    </small>
                    <small class="text-danger" v-if="documentError === 3">
                      Supported File Types are {{ acceptedFiles.join(", ") }}
                    </small>
                   <!-- <small class="text-danger" v-if="requiredPictures ">
                    Please attach at least one picture.
                  </small> -->
                    <div class="d-flex flex-wrap">
                      <b-badge
                        pill
                        variant="primary"
                        v-for="(item, index) in roomTypePictures[roomType.name]"
                        :key="index"
                        class="mr-50 mt-50"
                      >
                        {{ item.name }}
                        <feather-icon
                          size="24"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeRoomTypePicture(index, roomType.name)"
                          v-if="allowEdit"
                        />
                      </b-badge>
                    </div>
                </b-form-group>
                <template v-if="roomTypePictureURLs[roomType.name]">
                  <b-row  class="mb-1">
                      <b-col v-for="(url, index) in roomTypePictureURLs[roomType.name]" :key="index">
                          <b-img :src="url" rounded fluid> </b-img>
                      </b-col>
                  </b-row>
                </template>
  
              </b-col>
  
            </b-row>
          </template>
  
          <template>
            <!-- <b-row v-for="(roomType, index) in selectedRoomTypes" :key="'price_'+index">
              <b-col md="12" sm="12" lg="12" xl="12">
                <b-form-group :label-for="`${roomType.name}RoomTypePrice`">
                  <template #label> {{ roomType.name }} room type price </template>
                  <validation-provider #default="{ errors }" :name="`${roomType.name}RoomTypePrice`"
                      :rules="{ required, regex: /^[0-9-+]*$/i }">
                      <b-form-input :id="`${roomType.name}RoomTypePrice`" :v-model="`${roomTypePrices[roomType.name]}`" :state="errors.length > 0 ? false : null"
                      :placeholder="`${roomType.name} Room Type Price`" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row> -->
  
            <!-- <b-row v-for="(value, key) in roomTypeCount" :key="`count_${key}`">
              <b-col md="12" sm="12" lg="12" xl="12">
                !-- @change="changeRoomTypePrice(key, $event)" --
                <b-form-group :label-for="`${key}RoomTypeCount`">
                  <template #label> Number of {{ key }} rooms </template>
                  <validation-provider #default="{ errors }" :name="`${key}RoomTypeCount`"
                      :rules="{ required, regex: /^[0-9]*$/i }">
                      <b-form-input :id="`${key}RoomTypeCount`"  v-model="roomTypeCount[key]" :state="errors.length > 0 ? false : null"
                      :placeholder="`Number of ${key} rooms`" :ref="`${key}RoomTypeCount`" disabled/>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row> -->

            <!-- <b-row v-for="(value, key) in roomTypeCount" :key="`count_${key}`">
              <b-col md="12" sm="12" lg="12" xl="12">
                <strong>{{ key }}</strong>
                <b-row>
                  <b-col v-for="(value_userType, key_userType) in userTypes" :key="`userType_${key_userType}`" md="4" sm="4" lg="4" xl="4">
                    <b-form-group :label-for="`${key}-${key_userType}-RoomTypeCount`">
                      <template #label> Number of rooms for <br>{{ userTypeNames(value_userType) }} persons </template>
                      <validation-provider #default="{ errors }" :name="`${key}-${key_userType}-RoomTypeCount`"
                          :rules="{ required, regex: /^[0-9]*$/i }">
                          <template v-if="key in roomTypeCount">
                          <b-form-input :id="`${key}-${key_userType}-RoomTypeCount`"  v-model="roomTypeCount[key][value_userType]" :state="errors.length > 0 ? false : null"
                          :placeholder="`${key} Room Type Count`" :ref="`${key}-${key_userType}-RoomTypeCount`" disabled/>
                          </template>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row> -->
          </template>
          <template v-if="allowEdit">
          <b-form-group class="text-right">
            <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
              Update
            </b-button>
          </b-form-group>
        </template>
        </b-form>
      </validation-observer>
      <b-overlay :show="show" spinner-variant="primary" no-wrap />
    </b-modal>
  </template>
  
  <script>
  import Vue from "vue";
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import draggable from "vuedraggable";
  import util from "@/util.js";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VuePerfectScrollbar,
      draggable,
    },
    mixins: [util],
    props: ["showModal", "mess", "allowEdit"],
    data() {
      return {
        show: false,
        perfectScrollbarSettings: {
          maxScrollbarLength: 150,
          wheelPropagation: false,
          useBothWheelAxes: false,
          suppressScrollX: true,
        },
        banks: [],
        // checkType: true,
        selectedBank: null,
        accountTitle: "",
        accountNumber: "",
        name: "",
        description: "",
        phone: "",
        address: "",
        pictures: [],
        pictureNames: [],
        pictureURLs: [],
        // selectedHotel: null,
        // hotels: [],
        selectedManagingHq: null,
        managingHqs: [],
        selectedCity: null,
        cities: [],
        // floors: 0,
        iban:'',
        selectedRoomTypes: null,
        roomTypes: [],
        required,
        messFieldsData: [],
        acceptedFiles: [".jpg", ".jpeg", ".png", ".svg"],
        documentError: 0,
        maxFilesCount: 5,
        roomTypePictures: {},
        roomTypePictureNames: {},
        roomTypePictureURLs: {},
        roomTypePrices: {},
        roomTypeCount: {},
        requiredPictures: false,
      };
    },
    async mounted() {
      if (this.showModal) {
        try {
          // let res = await this.getHotelsUnpaginated()
          // this.hotels = res.data
          // if(this.hotels){
          //     this.selectedHotel = this.hotels[0]
          // }
          let res = await this.getBookingCitiesUnpaginated({})
          this.cities = res.data
          // if(this.cities){
          //     this.selectedCity = this.cities[0]
          // }
          res = await this.getManagingHqsUnpaginated()
          this.managingHqs = res.data
          // if(this.managingHqs){
          //     this.selectedManagingHq = this.managingHqs[0]
          // }        
          res = await this.getRoomTypesUnpaginated()
          this.roomTypes = res.data
          // if(this.roomTypes){
          //     this.selectedRoomTypes = this.roomTypes[0]
          // }
          res = await this.getBanksUnpaginated({})
          this.banks = res.data

          this.name = this.mess.name
          this.description = this.mess.description
          this.phone = this.mess.phone_no
          this.address = this.mess.location.address
          // this.accountNumber = this.mess.account_no
          this.accountTitle = this.mess.account_title
          this.iban = this.mess.iban;
        // this.accountNumber = this.mess.account_number;

        // Set checkType based on the fetched data
        // this.checkType = true; 
        // if (!this.accountNumber && this.iban) 
        // {
        //     this.checkType = false; 
        // } 
        // else if (this.accountNumber && !this.iban) 
        // {
        //     this.checkType = true; 
        // }


          // for(let i=0; i<this.hotels.length; i++){
          //   if(this.hotels[i].id == this.mess.hotel.id){
          //       this.selectedHotel = this.hotels[i]
          //   }
          // }

          for(let i=0; i<this.cities.length; i++){
            if(this.cities[i].id == this.mess.city.id){
                this.selectedCity = this.cities[i]
            }
          }

          for(let i=0; i<this.managingHqs.length; i++){
            if(this.managingHqs[i].id == this.mess.managing_hq.id){
                this.selectedManagingHq = this.managingHqs[i]
            }
          }
          if(this.mess.bank){
            for(let i=0; i<this.banks.length; i++){
              if(this.banks[i].id == this.mess.bank){
                  this.selectedBank = this.banks[i]
              }
            }
          }

          let filteredRoomTypes = []

          if(this.mess.room_types){

            for(let i=0; i<this.roomTypes.length; i++){
                if(this.mess.room_types.some(e => e.id == this.roomTypes[i].id)){
                    filteredRoomTypes.push(this.roomTypes[i])
                }
            }

            await this.setRoomTypes(filteredRoomTypes)

            // for(let i=0; i<this.selectedRoomTypes.length; i++){
            //     if(this.mess.room_type_details[i].room_type.id == this.selectedRoomTypes[i].id){
            //         Vue.set(this.roomTypePrices, this.selectedRoomTypes[i].name, this.mess.room_type_details[i].price);
            //         Vue.set(this.roomTypeCount, this.selectedRoomTypes[i].name, 0);
            //     }
            // }

            for(let i=0; i<this.selectedRoomTypes.length; i++){
                Vue.set(this.roomTypePrices, this.selectedRoomTypes[i].name, {});
                Vue.set(this.roomTypeCount, this.selectedRoomTypes[i].name, {});
            }
            for(let i=0; i<this.mess.room_type_details.length; i++){
                Vue.set(this.roomTypePrices[this.mess.room_type_details[i].room_type.name], parseInt(this.mess.room_type_details[i].room_category)-1, this.mess.room_type_details[i].price)
            }

            // for(let i=0; i<this.mess.no_of_rooms.rooms_by_type.length; i++){
                
            //     if(this.selectedRoomTypes.some(e => e.id == this.mess.no_of_rooms.rooms_by_type[i].room_type)){
            //         Vue.set(this.roomTypeCount, this.mess.no_of_rooms.rooms_by_type[i].room_type_name, this.mess.no_of_rooms.rooms_by_type[i].count);
            //     }
            // }

            for(let i=0; i<this.mess.no_of_rooms.rooms_by_type.length; i++){
              Vue.set(this.roomTypeCount[this.mess.no_of_rooms.rooms_by_type[i].room_type_name], parseInt(this.mess.no_of_rooms.rooms_by_type[i].room_category)-1, this.mess.no_of_rooms.rooms_by_type[i].count)
            }

            if (this.mess.images) {
                for(let i=0; i<this.mess.images.length; i++){
                    this.pictureURLs.push(this.mess.images[i].path)
                    fetch(this.mess.images[i].path)
                        .then((response) => response.blob())
                        .then((blob) => {
                        this.$refs.messPictures.files.push(new File([blob], this.mess.images[i].name))
                        this.pictures.push(this.$refs.messPictures.files[0]);
                        });
                }
            }

            // if(this.mess.room_type_details){
            //     for(let i=0; i<this.mess.room_type_details.length; i++){
            //         if(this.mess.room_type_details[i].room_images){
            //             for(let j=0; j<this.mess.room_type_details[i].room_images.length; j++){

            //                 if(!(this.mess.room_type_details[i].room_type.name in this.roomTypePictures)){
            //                     Vue.set(this.roomTypePictures, this.mess.room_type_details[i].room_type.name, []);
            //                 }
            //                 if(!(this.mess.room_type_details[i].room_type.name in this.roomTypePictureNames)){
            //                     Vue.set(this.roomTypePictureNames, this.mess.room_type_details[i].room_type.name, []);
            //                 }
            //                 if(!(this.mess.room_type_details[i].room_type.name in this.roomTypePictureURLs)){
            //                     Vue.set(this.roomTypePictureURLs, this.mess.room_type_details[i].room_type.name, []);
            //                 }

            //                 fetch(this.mess.room_type_details[i].room_images[j].path)
            //                     .then((response) => response.blob())
            //                     .then((blob) => {

            //                     let temp = this.roomTypePictures[this.mess.room_type_details[i].room_type.name]
            //                     temp.push(new File([blob], this.mess.room_type_details[i].room_images[j].name))
            //                     this.$refs[`${this.mess.room_type_details[i].room_type.name}RoomTypePictures`][0].files.push(temp)
            //                     // this.$refs[`${this.mess.room_type_details[i].room_type.name}RoomTypePictures`].files.push(temp)
            //                     Vue.set(this.roomTypePictures, this.mess.room_type_details[i].room_type.name, temp);

            //                     temp = this.roomTypePictureNames[this.mess.room_type_details[i].room_type.name]
            //                     temp.push(this.mess.room_type_details[i].room_images[j].name)
            //                     Vue.set(this.roomTypePictureNames, this.mess.room_type_details[i].room_type.name, temp);
                        
            //                     temp = this.roomTypePictureURLs[this.mess.room_type_details[i].room_type.name]
            //                     temp.push(this.mess.room_type_details[i].room_images[j].path)
            //                     Vue.set(this.roomTypePictureURLs, this.mess.room_type_details[i].room_type.name, temp);

            //                     }).then(() => {
            //                         for (const key of Object.keys(this.roomTypePictures)) {
            //                           if (this.roomTypePictures[key].length > 1) {
            //                               this.roomTypePictures[key] = this.roomTypePictures[key].splice(0, 1);
            //                               this.roomTypePictureNames[key] = this.roomTypePictureNames[key].splice(0, 1);
            //                               this.roomTypePictureURLs[key] = this.roomTypePictureURLs[key].splice(0, 1);
            //                             }
            //                         }

            //                     });
            //             }
            //         }
            //     }
            // }


            if(this.mess.room_images){
                for(let i=0; i<this.mess.room_images.length; i++){

                    if(!(this.mess.room_images[i].room_type_name in this.roomTypePictures)){
                        Vue.set(this.roomTypePictures, this.mess.room_images[i].room_type_name, []);
                    }
                    if(!(this.mess.room_images[i].room_type_name in this.roomTypePictureNames)){
                        Vue.set(this.roomTypePictureNames, this.mess.room_images[i].room_type_name, []);
                    }
                    if(!(this.mess.room_images[i].room_type_name in this.roomTypePictureURLs)){
                        Vue.set(this.roomTypePictureURLs, this.mess.room_images[i].room_type_name, []);
                    }

                    fetch(this.mess.room_images[i].path)
                        .then((response) => response.blob())
                        .then((blob) => {

                        let temp = this.roomTypePictures[this.mess.room_images[i].room_type_name]
                        temp.push(new File([blob], this.mess.room_images[i].name))
                        this.$refs[`${this.mess.room_images[i].room_type_name}RoomTypePictures`][0].files.push(temp)
                        // this.$refs[`${this.mess.room_type_details[i].room_type.name}RoomTypePictures`].files.push(temp)
                        Vue.set(this.roomTypePictures, this.mess.room_images[i].room_type_name, temp);

                        temp = this.roomTypePictureNames[this.mess.room_images[i].room_type_name]
                        temp.push(this.mess.room_images[i].name)
                        Vue.set(this.roomTypePictureNames, this.mess.room_images[i].room_type_name, temp);
                
                        temp = this.roomTypePictureURLs[this.mess.room_images[i].room_type_name]
                        temp.push(this.mess.room_images[i].path)
                        Vue.set(this.roomTypePictureURLs, this.mess.room_images[i].room_type_name, temp);

                        })
              }
            }

          }
        } catch (error) {
          this.displayError(error);
        }
      }
    },
    methods: {
      ...mapActions({
        updateMess: "appData/updateMess",
        // getHotelsUnpaginated: "appData/getHotelsUnpaginated",
        getBookingCitiesUnpaginated: "appData/getBookingCitiesUnpaginated",
        getManagingHqsUnpaginated: "appData/getManagingHqsUnpaginated",
        getRoomTypesUnpaginated: "appData/getRoomTypesUnpaginated",
        getBanksUnpaginated: "appData/getBanksUnpaginated",
      }),
      async validationForm() {
        const success = await this.$refs.messEditFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      
      selectAllRoomTypes() {
        this.selectedRoomTypes = this.roomTypes
      },
      async submit() {
        try {
          this.show = true;
          let formData = new FormData();
          for(let i = 0; i < this.pictures.length; i++){
            formData.append(`Mess_${i}`, this.pictures[i]);
          }
  
          let selectedRoomTypesList = []
          let temp = {}
          for(let i = 0; i < this.selectedRoomTypes.length; i++) {
            // selectedRoomTypesList.push(this.selectedRoomTypes[i]);
            temp = {}
            temp['id'] = this.selectedRoomTypes[i].id
            temp['name'] = this.selectedRoomTypes[i].name
            temp['price'] = this.roomTypePrices[this.selectedRoomTypes[i].name]
            temp['count'] = this.roomTypeCount[this.selectedRoomTypes[i].name]
            selectedRoomTypesList.push(temp)
            if(this.roomTypePictures[this.selectedRoomTypes[i].name]){
              for(let j = 0; j < this.roomTypePictures[this.selectedRoomTypes[i].name].length; j++){
                formData.append(`${this.selectedRoomTypes[i].name}_${j}`, this.roomTypePictures[this.selectedRoomTypes[i].name][j]);
              }
            }
          }
          // if(this.accountNumber){
          //   if (this.accountNumber.trim() === "") {
          //     this.accountNumber = null;
          //   }
          // }

          // if(this.iban){
          //   if (this.iban.trim() === "") {
          //     this.iban = null;
          //   }
          // }

          this.checkType = !this.accountNumber; 
          let dataToInsert = {
            name: this.name,
            description: this.description,
            // hotel_id: this.selectedHotel.id,
            managing_hq_id: this.selectedManagingHq.id,
            city_id: this.selectedCity.id,
            mess_type: 'Standard',
            iban : this.iban,
            bank: this.selectedBank.id,
            account_title: this.accountTitle,
            phone_no: this.phone,
            address: this.address,
            room_types: selectedRoomTypesList,
  
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
          };
          formData.append("data", JSON.stringify(dataToInsert));

          const res = await this.updateMess({
            payload: formData,
            pk: this.mess.id,
          });
          if (res.status === 200) {
            this.show = false; 
            this.$swal({
              title: "Mess updated successfully",
              icon: "success",
            });
            // await this.resetModal();
            this.$nextTick(() => {
              this.$bvModal.hide("mess-edit-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
  
      async uploadMessPicture(event) {
        this.documentError = 0;
        const files = event.target.files;
        if (this.pictures.length >= this.maxFilesCount) {
          this.documentError = 1;
          return;
        }
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const ext = file.name.slice(
            ((file.name.lastIndexOf(".") - 1) >>> 0) + 1
          );
          if (file.size > this.maxFileSize * 1000 * 1024) {
            this.documentError = 2;
            return;
          }
          if (!this.acceptedFiles.includes(ext)) {
            this.documentError = 3;
            return;
          }
        }
  
        for (let i = 0; i < files.length; i++) {
          this.pictures.push(files[i]);
          // this.pictureNames.push(files[i].name)
          this.pictureURLs.push(URL.createObjectURL(files[i]))
        }
      },
  
      async uploadRoomTypePicture(roomTypeName, event) {
        this.documentError = 0;
        const files = event.target.files;
        if(!(roomTypeName in this.roomTypePictures)){
          // this.roomTypePictures[roomTypeName] = []
          Vue.set(this.roomTypePictures, roomTypeName, []);
        }
        if(!(roomTypeName in this.roomTypePictureNames)){
          // this.roomTypePictureNames[roomTypeName] = []
          Vue.set(this.roomTypePictureNames, roomTypeName, []);
        }
        if(!(roomTypeName in this.roomTypePictureURLs)){
          // this.roomTypePictureURLs[roomTypeName] = []
          Vue.set(this.roomTypePictureURLs, roomTypeName, []);
        }
        if (this.roomTypePictures[roomTypeName].length >= this.maxFilesCount) {
          this.documentError = 1;
          return;
        }
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const ext = file.name.slice(
            ((file.name.lastIndexOf(".") - 1) >>> 0) + 1
          );
          if (file.size > this.maxFileSize * 1000 * 1024) {
            this.documentError = 2;
            return;
          }
          if (!this.acceptedFiles.includes(ext)) {
            this.documentError = 3;
            return;
          }
        }
  
        for (let i = 0; i < files.length; i++) {
          // this.roomTypePictures[roomTypeName].push(files[i]);
          // this.roomTypePictureNames[roomTypeName].push(files[i].name)
          // this.roomTypePictureURLs[roomTypeName].push(URL.createObjectURL(files[i]))
  
          let temp = this.roomTypePictures[roomTypeName]
          temp.push(files[i])
          Vue.set(this.roomTypePictures, roomTypeName, temp);
  
          temp = this.roomTypePictureNames[roomTypeName]
          temp.push(files[i].name)
          Vue.set(this.roomTypePictureNames, roomTypeName, temp);
  
          temp = this.roomTypePictureURLs[roomTypeName]
          temp.push(URL.createObjectURL(files[i]))
          Vue.set(this.roomTypePictureURLs, roomTypeName, temp);
  
        }
      },
  
      formatMessPictureNames(files) {
        return this.pictures.length === 1
          ? this.pictures[0].name
          : `${this.pictures.length} files selected`;
      },
  
      removeMessPicture(index) {
        this.pictures.splice(index, 1);
        this.pictureURLs.splice(index, 1);
      },
  
      removeRoomTypePicture(index, roomTypeName) {
        this.roomTypePictures[roomTypeName].splice(index, 1);
        this.roomTypePictureNames[roomTypeName].splice(index, 1);
        this.roomTypePictureURLs[roomTypeName].splice(index, 1);
      },

      async setRoomTypes(roomTypes){
        this.selectedRoomTypes = roomTypes
      },
  
      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getUser" }),
    },
    watch: {
        accountNumber(newVal) {
          if (newVal && this.iban) {
            this.iban = '';
          }
        },
        iban(newVal) {
          if (newVal && this.accountNumber) {
            this.accountNumber = ''; 
          }
        },
      selectedRoomTypes(newValue, oldValue) {
        if (newValue) {
          let temp = []
          for(let i=0; i<newValue.length; i++){
            temp.push(newValue[i].name)
          // Vue.set(this.roomTypePrices, newValue[i].name, 0);
          // Vue.set(this.roomTypeCount, newValue[i].name, 0);
          Vue.set(this.roomTypePrices, newValue[i].name, {});
          Vue.set(this.roomTypeCount, newValue[i].name, {});
          for(var key of Object.keys(this.userTypes)){
            Vue.set(this.roomTypePrices[newValue[i].name], this.userTypes[key], 0)
            Vue.set(this.roomTypeCount[newValue[i].name], this.userTypes[key], 0)
          }
          }
          for(let i=0; i<this.roomTypes.length; i++){
            if( !(temp.includes(this.roomTypes[i].name)) ){
              this.roomTypePictures[this.roomTypes[i].name] = []
              this.roomTypePictureNames[this.roomTypes[i].name] = []
              this.roomTypePictureURLs[this.roomTypes[i].name] = []
              // this.roomTypePrices[this.roomTypes[i].name] = 0
              Vue.delete(this.roomTypePrices, this.roomTypes[i].name)
              Vue.delete(this.roomTypeCount, this.roomTypes[i].name)
              // Vue.set(this.roomTypePrices, this.roomTypes[i].name, 0);
            }
          }
        }
      },
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }
  </style>
