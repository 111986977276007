<template>
    <div>
      <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
     <div class="d-flex justify-content-center align-items-center flex-nowrap">
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-form-input id="messNameFilter" v-model="messNameFilter"
            placeholder="Search by mess name" />
        </div>

        <!-- <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-form-input id="hotelNameFilter" v-model="hotelNameFilter"
            placeholder="Search by hotel name" />
        </div> -->

  
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-form-input id="cityNameFilter" v-model="cityNameFilter"
            placeholder="Search by city name" />
        </div>

        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-form-input id="managingHqNameFilter" v-model="managingHqNameFilter"
            placeholder="Search by managing hq name" />
        </div>  

      </div>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-button variant="primary" pill @click="filter">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </div>
  
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="RefreshCwIcon" class="mr-50" />
            <span class="align-middle">Refresh</span>
          </b-button>
        </div>
  
        <div class="text-right">
          <b-button variant="primary" pill @click="createMess">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Create</span>
          </b-button>
        </div>
        <div class="text-right ml-3">
          <b-button v-if="getLoggedInUser.role_data.code_name == 'su' || getLoggedInUser.role_data.code_name == 'bk__agm'"
             variant="info" pill @click="downloadMessesList">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Download Messes List</span>
          </b-button>
        </div>
      </div>

      <b-card>
        <b-table responsive :fields="fields" :items="messes" details-td-class="m-0 p-0" small
          v-if="hasPermission('mess_show')" :per-page="0" :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(no_of_rooms)="row">
            <p>{{ row.item.no_of_rooms ? "Total: " + row.item.no_of_rooms.total : "" }}</p>
            <!-- <p v-for="(room_type, index) in row.item.no_of_rooms.rooms_by_type" :key="index">
              {{room_type.room_type_name}} - {{room_type.room_category_name}}: {{room_type.count}}
            </p> -->
          </template>
          <!-- <template #cell(room_type_details)="row">
            {{ row.item.room_type_details ? row.item.room_type_details.user : "" }}
            <p v-for="(room_type_detail, index) in row.item.room_type_details" :key="index">
                Room Type: {{room_type_detail.room_type.name}}  Price: {{room_type_detail.price}}
            </p>
          </template> -->
          <template #cell(created_by_data)="row">
            {{ row.item.created_by_data ? row.item.created_by_data.user : "" }}
          </template>
          <template #cell(updated_by_data)="row">
            {{ row.item.updated_by_data ? row.item.updated_by_data.user : "" }}
          </template>
          <template #cell(manage)="row">
          <div class="d-flex align-items-center">
            <b-button variant="info" pill size="sm" class="mr-1" @click="viewMess(row.item)"
              v-if="hasPermission('mess_read')">
              View
            </b-button>
            <template>
              <b-button variant="info" pill size="sm" class="mr-1" @click="editMess(row.item)"
                v-if="hasPermission('mess_update')">
                Edit
              </b-button>
            </template>
            <template v-if="!row.item.is_unavailable">
              <b-button variant="warning" pill size="sm" class="text-truncate mr-1" @click="makeUnavailable(row.item)"
                v-if="hasPermission('mess_update')">
                Make unavailable
              </b-button>
            </template>
            <template v-if="row.item.is_unavailable">
              <b-button variant="success" pill size="sm" class="text-truncate mr-1" @click="makeAvailable(row.item)"
                v-if="hasPermission('mess_update')">
                Make available
              </b-button>
            </template>
            <!-- <template v-if="!row.item.assigned">
              <b-button variant="danger" pill size="sm" class="mr-1" @click="deleteMess(row.item)"
                v-if="hasPermission('mess_delete')">
                Delete
              </b-button>
            </template> -->
            <template v-if="!row.item.assigned">
              <b-button variant="warning" pill size="sm" class="mr-1 text-truncate" @click="ShowRooms(row.item.id)" v-if="hasPermission('mess_read')">
                Show Rooms
              </b-button>
            </template>
            </div>
          </template>
        </b-table>
        <b-row>
                <b-col md="2">
                    <div style="float:left">
                        <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
                        <h5 style="margin: 0; display: inline;" align="right">
                            <strong>
                                {{totalDataLength}}
                            </strong>
                        </h5>
                    </div>
                </b-col>
            </b-row>
        <div class="d-flex justify-content-center align-items-center flex-nowrap m-2">
          <b-pagination size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
        </div>
      </b-card>
      <mess-create-modal @modalClosed="onModalClosed"
        :showModal="visible" :key="`create-${messCreateModalCount}`" />
      <mess-edit-modal :mess="mess" :allowEdit="allowEdit" @modalClosed="onModalClosed"
        :showModal="visible" :key="`edit-${messEditModalCount}`" />
       <room-show-modal :key="`show-${roomShowModalCount}`" :showModal="visible" @modalClosed="onModalClosed" :messId="messId" />
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import util from "@/util.js";
  import MessCreateModal from "@/components/booking/admin/MessCreateModal.vue";
  import MessEditModal from "@/components/booking/admin/MessEditModal.vue";
  import RoomShowModal from "@/components/booking/admin/RoomShowModal.vue";
  
  export default {
    components: {
      MessCreateModal,
      MessEditModal,
      RoomShowModal,
    },
    data() {
      return {
        fields: [
          { key: "manage", label: "Manage" },
        //   { key: "id", label: "Mess ID" },
          { key: "name", label: "Name" },
          { key: "phone_no", label: "Phone Number" },
          { key: "bank_name", label: "Bank" },
          { key: "account_title", label: "Account Title" },
          { key: "iban", label: "IBAN" },
          // { key: "hotel.name", label: "Hotel" },
          { key: "city.name", label: "City" },
          { key: "managing_hq.name", label: "Managing HQ" },
          { key: "mess_secretary.user_data.name", label: "Mess Secretary" },
          { key: "no_of_rooms", label: "Number of Rooms" },
          // { key: "floors", label: "Number of Floors" },
          // { key: "room_type_details", label: "Available Room Types" },
          { key: "created_at", label: "Created At" },
          { key: "created_by_data", label: "Created By" },
          { key: "updated_at", label: "Last updated" },
          { key: "updated_by_data", label: "Updated By" },
        ],
        currentPage: 1,
        perPage: 0,
        totalItems: 0,
        mess: null,
        messes: [],
        messCreateModalCount: 0,
        messEditModalCount: 0,
        roomShowModalCount: 0,
        visible: false,
        messNameFilter: "",
        // hotelNameFilter: "",
        cityNameFilter: "",
        managingHqNameFilter: "",
        isBusy: false,
        allowEdit: true,
        messId: null,
        totalDataLength: 0,
        show: false,
        exportXls: false,
      };
    },
    mixins: [util],
    async mounted() {
      // await this.search();
    },
    methods: {
      ...mapActions({
        getMesses: "appData/getMesses",
        getMess: "appData/getMess",
        updateMess: "appData/updateMess",
        delete: "appData/deleteMess",
        downloadMessesReport: "appData/downloadMessesReport",
        // updateMessAttribute: "appData/updateMessAttribute"
      }),
      async filter() {
        this.currentPage = 1;
        await this.search();
      },
      async search() {
        this.isBusy = true;
        await this.fetchPaginatedData();
        this.isBusy = false;
      },
      createMess() {
          this.messCreateModalCount += 1;
          this.visible = true;
          this.$nextTick(() => {
            this.$bvModal.show("mess-create-modal");
          });
      },
      editMess(mess) {
        this.mess = mess;
        this.messEditModalCount += 1;
        this.visible = true;
        this.allowEdit = true;
        this.$nextTick(() => {
          this.$bvModal.show("mess-edit-modal");
        });
      },
      viewMess(mess) {
        this.mess = mess;
        this.messEditModalCount += 1;
        this.visible = true;
        this.allowEdit = false;
        this.$nextTick(() => {
            this.$bvModal.show("mess-edit-modal");
        });
      },
      ShowRooms(id) {
        this.roomShowModalCount += 1
        this.visible = true;
        this.allowEdit = false;
        this.messId = id
        this.$nextTick(() => {
          this.$bvModal.show("room-show-modal");
        })
      },
      //HARD DELETE
    //   async deleteMess(mess) {
    //   try {
    //     this.$swal({
    //       title:
    //         "Are you sure?",
    //       icon: "warning",
    //       showCloseButton: true,
    //       showCancelButton: true,
    //       confirmButtonText: "Confirm",
    //       customClass: {
    //         confirmButton: "btn btn-primary",
    //         cancelButton: "btn btn-danger ml-1",
    //       },
    //       buttonsStyling: false,
    //     }).then(async (result) => {
    //       if (result.value) {
    //         try {
    //           const res = await this.delete({
    //             pk: mess.id,
    //           });
    //           if (res.status === 204) {
    //             this.$swal({
    //               title: "Mess deleted successfully",
    //               icon: "success",
    //             });
    //             this.$nextTick(() => {
    //               this.search();
    //             });
    //             this.$emit("modalClosed");
    //           }
    //         } catch (error) {
    //           this.show = false;
    //           if (error.response.status == 500) {
    //             // this.displayError("Kindly make sure this mess does not have any dependencies");
    //             this.$swal({
    //               title: "Kindly make sure this mess does not have any dependencies",
    //               icon: "error",
    //             });
    //           }
    //           else {
    //             this.displayError(error);
    //           }
    //         }
    //       } else {
    //         this.show = false;
    //       }
    //     });
    //   } catch (error) {
    //     this.displayError(error);
    //   }
    // },


      // SOFT DELETE
      deleteMess(mess) {
        try {
          this.$swal({
            title:
              "Are you sure?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              try {
                const res = await this.updateMess({
                  payload: {
                    is_delete: true,
                    updated_by: this.getLoggedInUser.id,
                  },
                  pk: mess.id,
                });
                let response_string = "Mess deleted successfully";
                let response_msg = response_string;
                if (res.data.response_msg) {
                  response_msg = response_msg.concat(res.data.response_msg);
                }
                if (res.status === 200) {
                  this.$swal({
                    title: response_msg,
                    icon: "success",
                  });
                  this.$nextTick(() => {
                    this.fetchPaginatedData();
                  });
                  this.$emit("modalClosed");
                }
              } catch (error) {
                this.show = false;
                this.displayError(error);
              }
            } else {
              this.show = false;
            }
          });
        } catch (error) {
          this.displayError(error);
        }
      },

      async onModalClosed() {
        await this.fetchPaginatedData();
        this.allowEdit = true;
        this.visible = false;
      },
      async fetchPaginatedData() {
        try {
          let tempParams = {
            pageNumber: this.currentPage,
          };
          if (this.messNameFilter) {
            tempParams["name"] = this.messNameFilter;
          }
          // if (this.hotelNameFilter) {
          //   tempParams["hotel_name"] = this.hotelNameFilter;
          // }
          if (this.cityNameFilter) {
            tempParams["city_name"] = this.cityNameFilter;
          }
          if (this.managingHqNameFilter) {
            tempParams["managing_hq_name"] = this.managingHqNameFilter;
          }
          const res = await this.getMesses(tempParams);
          this.messes = res.data.results;
          
          this.totalItems = res.data.count;
          this.perPage = res.data.per_page;
          this.totalDataLength = res.data.count;
        } catch (error) {
          this.displayError(error);
        }
      },
      makeUnavailable(mess){
        try {
          this.$swal({
            title:
              "Are you sure?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              try {
                const res = await this.updateMess({
                  payload: {
                    minor_patch: true,
                    is_unavailable: true,
                    updated_by: this.getLoggedInUser.id,
                  },
                  pk: mess.id,
                });
                let response_string = "Mess is now available";
                let response_msg = response_string;
                if (res.data.response_msg) {
                  response_msg = response_msg.concat(res.data.response_msg);
                }
                if (res.status === 200) {
                  this.$swal({
                    title: response_msg,
                    icon: "success",
                  });
                  this.$nextTick(() => {
                    this.fetchPaginatedData();
                  });
                  this.$emit("modalClosed");
                }
              } catch (error) {
                this.show = false;
                this.displayError(error);
              }
            } else {
              this.show = false;
            }
          });
        } catch (error) {
          this.displayError(error);
        }
      },
      makeAvailable(mess){
        try {
          this.$swal({
            title:
              "Are you sure?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              try {
                const res = await this.updateMess({
                  payload: {
                    minor_patch: true,
                    is_unavailable: false,
                    updated_by: this.getLoggedInUser.id,
                  },
                  pk: mess.id,
                });
                let response_string = "Mess is now available";
                let response_msg = response_string;
                if (res.data.response_msg) {
                  response_msg = response_msg.concat(res.data.response_msg);
                }
                if (res.status === 200) {
                  this.$swal({
                    title: response_msg,
                    icon: "success",
                  });
                  this.$nextTick(() => {
                    this.fetchPaginatedData();
                  });
                  this.$emit("modalClosed");
                }
              } catch (error) {
                this.show = false;
                this.displayError(error);
              }
            } else {
              this.show = false;
            }
          });
        } catch (error) {
          this.displayError(error);
        }
      },
      async downloadMessesList(){
         this.show = true;
         this.exportXls = true;
         let payload = {};
        try {
        const res = await this.downloadMessesReport({
          payload
        });
        if (res.status === 200) {
          var currentdate = new Date();
          const url = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            'Mess Details List Excel' + currentdate.getDate() + "-" + + (currentdate.getMonth() + 1) + "-" + currentdate.getFullYear() + " --- " + currentdate.getHours() + "-" + currentdate.getMinutes() + "-" + currentdate.getSeconds() + ".xlsx"
          );
          this.show = false;
          document.body.appendChild(link);
          link.click();
          this.$swal({
            title: "Download successful",
            icon: "success",
            timer: 1500,
          });
        }
        if (res.status === 204) {
          this.$swal({
            title: "No Record Found",
            icon: "info",
          });
        }
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
      },
    },
    computed: {
      ...mapGetters({
        hasPermission: "appData/hasPermission",
        getLoggedInUser: "appData/getUser",
      }),
    },
    watch: {
      currentPage: async function (val) {
        await this.fetchPaginatedData();
      },
    },
  };
  </script>
  
  <style></style>
  